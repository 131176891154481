// import { REACT_APP_BASE_URL } from "@env";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// type prepareHeaders = (
//   headers: Headers,
//   api: {
//     getState: () => unknown
//     extra: unknown
//     endpoint: string
//     type: 'query' | 'mutation'
//     forced: boolean | undefined
//   }
// ) => Headers | void

/**
 * Base API service to be used across the application.
 * Endpoints can be injected using the .injectEndpoints method.
 */

const REACT_APP_BASE_URL = "/";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL || REACT_APP_BASE_URL,
  // baseUrl: "http://localhost:5000/api/user",
  prepareHeaders: async (headers) => {
    const token = await localStorage.getItem("authToken");
    headers.set("Authorization", `Bearer ${token}`);

    return headers;
  },
  // credentials: "include",
  /*
   * This is added to timeout the API in case no response is received in 10s
   */
  timeout: 10000,
})

const baseQueryWithReauth = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseQuery(args, api, extraOptions)

  if (result?.error && result?.error?.originalStatus === 401 && (args && !args?.force)) {
    // Redirect to login page if 401 response
    window.location.href = "/auth";
    return {
      error: {
        status: 401,
        data: "Unauthorized",
      },
    };
  }

  return result
}

export const baseApi = createApi({
  reducerPath: "baseApiReducer",
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
})